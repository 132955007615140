/** @type {import('tailwindcss').Config} */
export default {
    darkMode: 'class',
    content:
    [
      "./{pages,partials,layouts}/**/*.htm",
      "./resources/**/*.vue",
    ],
    safelist: [
      'text-red', 'bg-red/10', 'bg-black/10', 'text-black'
    ],
    theme: {
      container: {
        center: true,
        padding: {
          DEFAULT: '2rem',
          'md': '1rem',
          'lg': '2rem',
          'xl': '3rem'
        }
      },
      fontFamily: {
        display: 'Inter, sans-serif',
        sans: 'Inter, sans-serif',
        condensed: 'Roboto Condensed, sans-serif'
      },
      colors: {
        teal: {
          DEFAULT: '#1f8d9e',
          'light': '#00AECA'
        },
        slate: {
          DEFAULT: '#223455',
          'light': '#224C6E',
          'dark': '#131d2e'
        },
        cyan: {
          DEFAULT: '#1B98E0',
          'light': '#42BAFF'
        },
        gray: {
          DEFAULT: '#7A8599',
          'light': '#E8EDF0',
          'medium': '#C1C1C1'
        },
        blue: '#022185',
        indigo: '#3D4AFF',
        red: '#FF4242',
        orange: '#FF7700',
        emerald: '#00D9B4',
        white: '#FFFFFF',
        black: '#131F30',
      },
      extend: {
        boxShadow: {
          DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
          'sm':	'0 1px 2px 0 rgb(0 0 0 / 0.05)',
          'md':	'0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          'lg':	'0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
          'xl':	'0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
          '2xl':	'0 10px 50px 0px rgb(0 0 0 / 0.08)',
          'inner':	'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
          'none':	'0 0 #0000',
        },
        maxWidth: {
          'prose': '75ch'
        }
      },
    },
    plugins: [],
  }