import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'

const { theme } = resolveConfig(tailwindConfig)
const largeBreakpoint = parseInt(theme.screens.lg.replace("px", ""));
let windowWidth = window.innerWidth

function debounce(func, delay) {
    let timeoutId;
    return function () {
        clearTimeout(timeoutId);
        const args = arguments;
        timeoutId = setTimeout(() => func.apply(this, args), delay);
    }
}

function handleResize() {
    var navWrapper = document.getElementById('nav-wrapper');

    if (
        (windowWidth < largeBreakpoint && window.innerWidth > largeBreakpoint) ||
        (windowWidth > largeBreakpoint && window.innerWidth < largeBreakpoint)
     ) {
        closeOpenDropdown()
        closeOpenFlyout()
        resetDropdowns()
        document.getElementsByTagName('body')[0].classList.add('menu-open');
        navWrapper.style.paddingTop = document.getElementById('header').offsetHeight + 'px';
    }

    if (windowWidth < largeBreakpoint && window.innerWidth > largeBreakpoint) {
        document.getElementsByTagName('body')[0].classList.remove('menu-open');
        navWrapper.style.paddingTop = '0';
    }

    windowWidth = window.innerWidth
}

window.addEventListener('resize', debounce(handleResize, 100));

/**
 * Register click event listeners on all main menu parent buttons
 */
document.querySelectorAll('#main-menu > .parent > button').forEach(item => {
    item.addEventListener("click", (event) => {
        item.classList.contains('open') ? closeDropdown(item) : openDropdown(item);
    });
});

/**
 * Register click event listeners on all buttons in dropdown menus that have children
 */
document.querySelectorAll('#main-menu .dropdown .has-children > button').forEach(item => {
    item.addEventListener("click", (event) => {
        item.classList.contains('open') ? closeFlyout(item) : openFlyout(item);
    });
});

/**
 * Open a dropdown menu from the main menu based on the button item press
 */
function openDropdown(item) {
    // Close any open dropdowns
    closeOpenDropdown()

    item.querySelector('svg').dataset.icon = "times";

    // Get some stuff we need
    let dropdownWrapper = item.parentNode.querySelector('.dropdown-wrapper');

    // reset the dropdown to it's default position
    dropdownWrapper.style.right = 'auto';
    dropdownWrapper.style.left = 'auto';

    /**
     * We need to check if the dropdown goes outside of the viewport and move it accordingly!
     */
    let dropdownWrapperRightPosition = dropdownWrapper.getBoundingClientRect().right;
    let vw = document.documentElement.clientWidth

    // Determine how far the dropdown goes outside the viewport
    let viewportOverlap = dropdownWrapperRightPosition - vw;

    // If it's a positive value we need to move it by the distance outside the viewport + a bit of space for aesthetic reasons!
    if (viewportOverlap > 0) {
        /**
         * It might be better to use translateX on this instead of setting left and right
         */
        dropdownWrapper.style.right = viewportOverlap+16 + 'px';
        dropdownWrapper.style.left = -viewportOverlap-16 + 'px';
    }

    // Add some classes to the markup, CSS takes care of everything else!
    item.classList.add('open');
    dropdownWrapper.classList.add('open');
}

/**
 * Finds and hides the open dropdown from the main menu
 */
function closeOpenDropdown() {
    var openDropdown = document.querySelector("#main-menu .parent button.open");
    if (openDropdown) {
        closeDropdown(openDropdown);
    }
}

/**
 * Close a specific dropdown, based on the button item press
 */
function closeDropdown(item) {
    item.classList.remove('open');
    item.parentNode.querySelector('.dropdown-wrapper').classList.remove('open');
    closeOpenFlyout();
    item.querySelector('svg').dataset.icon = "angle-down";
}

/**
 * Open a flyout inside the dropdown
 */
function openFlyout(item) {
    // Close any open flyout
    closeOpenFlyout();
    item.querySelector('svg').dataset.icon = "times";

    // Select things we need
    let dropdownWrapper = item.parentNode.parentNode.parentNode;
    let dropdown = dropdownWrapper.querySelector('.dropdown');
    let flyout = item.parentNode.querySelector('.flyout');

    // Add some classes to the markup - css animates the flyout in!
    item.classList.add("open");
    flyout.classList.add('open');
    dropdownWrapper.classList.add('flyout-open');

    /**
     * We'll have to adjust height ourselves though
     */
    if (window.innerWidth > largeBreakpoint) {
        // Get the list element inside the flyout menu
        var list = flyout.querySelector(":scope > ul");

        // If the list is taller than the dropdown, increase the size of the dropdown!
        if (list.offsetHeight > dropdown.offsetHeight) {

            // Explicitly set the height of the dropdownWrapper so it replaces 'auto' - this means it will animate with css!
            dropdownWrapper.style.height = dropdown.clientHeight + "px";

            /**
             * Calculate the new height to use on the dropdown and flyout
             * 32 is the combined top and bottom padding on the flyout element
             * and setting style.height does take this into account
             */
            let newHeight = 32 + list.offsetHeight + "px";
            flyout.style.height = newHeight;
            dropdownWrapper.style.height = newHeight;
        }
    }

};

/**
 * Close the open flyout
 */
function closeOpenFlyout() {
    var openFlyout = document.querySelector('#main-menu .dropdown .has-children > button.open');
    if (openFlyout) {
        closeFlyout(openFlyout);
    }
}

/**
 * Close a flyout
 */
function closeFlyout(item) {
    item.querySelector('svg').dataset.icon = "list";
    let dropdownWrapper = item.parentNode.parentNode.parentNode;
    let flyout = item.parentNode.querySelector('.flyout');
    let dropdown = item.parentNode.parentNode;

    if (window.innerWidth >= largeBreakpoint) {
        dropdownWrapper.style.height = dropdown.offsetHeight + 'px';
    }

    // Remove some classes from the markup - css takes care of hiding the flyout
    flyout.classList.remove('open');
    dropdownWrapper.classList.remove("flyout-open");
    item.classList.remove("open");
}

function resetDropdowns() {
    document.querySelectorAll('#main-menu .dropdown-wrapper').forEach(item => {
        item.style.height = 'auto';
    });

    document.querySelectorAll('#main-menu .flyout').forEach(item => {
        item.style.height = 'auto';
    });
}